import { API } from 'aws-amplify'

// const apiName = "arch-api"
const apiName = "ARCH-Keystone-API"


export function updateClientTone(clientId, clientTone) {
    const path = "/client/update"

    const updateBody = { 
        body: {
            clientId: clientId,
            clientTone: clientTone
        }
    }
    const configs = {
        body: updateBody
    }

    API.post(apiName, path, configs)
        .then((res) => {
            console.log(res)
        }).catch((error) => {
            console.log(error.response);
        })
}