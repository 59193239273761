import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Card, CardContent, Grid, TextareaAutosize, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { SliderTable, DetailPanelContent } from '../components/generate_prompt_components'
import { API } from 'aws-amplify'

const GeneratePrompt = () => {
    // PROPS/CLIENT DATA FROM PREVIOUS PAGE
    const clientData = useLocation()
    const route = useNavigate()

    // DATA GRID STATE SETUP
    const [ duplArray, setDuplArray ] = useState([])
    const [ labelArray, setLabelArray ] = useState([])
    const [ querySelect, setQuerySelect ] = useState([])
    const [ queryRows, setQueryRows ] = useState([])
    const [ brandLabelSelect, setBrandLabelSelect ] = useState([])
    const [ brandLabelRows, setBrandLabelRows ] = useState([])
    
    // PARAMATER STATE SETUP
    const [ topK, setTopK ] = useState("200")
    const [ topP, setTopP ] = useState("0.4")
    const [ temperature, setTemperature ] = useState("0.4")
    const [ maxTokensToSample, setMaxTokens ] = useState("250")

    // PARAMETER GETTERS
    const handleTopK = (e) => {
        setTopK(e.target.value)
    }
    const handleTopP = (e) => {
        setTopP(e.target.value)
    }
    const handleTemp = (e) => {
        setTemperature(e.target.value)
    }
    const handleMaxTokens = (e) => {
        setMaxTokens(e.target.value)
    }

    // EDIT BUTTON ROUTE TO CLIENT INTAKE PAGE
    const editQuestions = () => {
        const configs = {
            from: "edit",
            clientDetails: clientData.state,
            qaDetails: queryRows
        }

        route("/add_client", {state: configs})
    }

    // GENERATE NEW PROMPT
    const generatePrompt = () => {
        console.log("PENDING PROMPT")

        // DATA GRID SELECT GETTERS
        const querySelectArray = []
        const labelSelectArray = []
        const qaIds = []
        const brandLabelIds = []
        querySelect.map((qSel) => {
            querySelectArray.push({ question: qSel.question, answer: qSel.answer, insight: qSel.insight })
            qaIds.push(qSel.id)
        })
        brandLabelSelect.map((bSel) => { 
            labelSelectArray.push( bSel.label ) 
            brandLabelIds.push( bSel.id )
        })

        // RESPONSE BODY SETTERS
        const responseBody = {
            queries: querySelectArray,
            brandMessageLabels: labelSelectArray,
            tone: clientData.state.tone
        }
        const params = {
            topK: topK,
            topP: topP,
            temperature: temperature,
            maxTokensToSample: maxTokensToSample
        }
        const generate_configs = {
            body: responseBody,
            response: true,
            queryStringParameters: params,
            brandMessageLabels: labelSelectArray
        }
        const save_configs = {
            clientId: clientData.state.clientId,
            qaIds: qaIds,
            bmlIds: brandLabelIds,
            tone: clientData.state.tone
        }
        const promptConfigs = {
            generate: generate_configs,
            save: save_configs
        }

        route('/prompt_response', { state: promptConfigs })
    }

    // DATAGRID COLUMN/ROW INIT
    const queryArray = []
    const brandLabelArray = []
    const queryColumns = [
        { field: 'question', headerName: 'Select Query For Prompt', width: '800' },
    ]
    const brandLabelColumns = [
        { field: 'label', headerName: 'Select Brand Message Label For Prompt', width: '800' }
    ]

    useEffect(() => {
        // const apiName = "arch-api"
        const apiName = "ARCH-Keystone-API"
        const clientPath = "/qa/client"
        const labelPath = "/brand-messaging-label"
    
        const params = {
            client_id: clientData.state.clientId,
            get_full_history: false
        }
        const configs = {
            queryStringParameters: params
        }
    
        API.get(apiName, clientPath, configs)
            .then((res) => {
                if(res.length === 0) route("/add_client", clientData)
                let sortedArray = res.sort((a, b) => {
                    if (a.ordinal > b.ordinal) {
                        return 1
                    } else if (a.ordinal < b.ordinal) {
                        return -1
                    } else {
                        return 0
                    }
                })
                setDuplArray(sortedArray)
            }).catch((error => {
                console.log(error)
            }))
        API.get(apiName, labelPath)
            .then((res) => {
                console.log(res)
                setLabelArray(res)
            })
    }, [clientData])

    useEffect(() => {
        // DATA GRID SETTERS
        try {
            duplArray.forEach((arr) => {
                queryArray.push({
                    id: arr.id,
                    question: arr.question,
                    answer: arr.answer,
                    insight: arr.insight
                })
            })
            labelArray.forEach((label) => {
                brandLabelArray.push({
                    id: label.id,
                    label: label.brand_messaging_label
                })
            })
        } catch (e) {
            console.log(e)
        }
        setQueryRows(queryArray)
        setBrandLabelRows(brandLabelArray)
    }, [duplArray, labelArray])

    const panelContent = useCallback((row) => <DetailPanelContent row={row} />, [])
    
    let pageStyle = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    }
    
    return(
        <div style={pageStyle}>
            <Typography variant="h3" textTransform="lowercase"> {clientData.state.clientName} </Typography>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: '15%',
                justifyContent: 'space-evenly',
                width: '75%'
            }}>
                <Button
                    variant='contained'
                    color="error"
                    disableElevation={true}
                    sx={{borderRadius: 0, textTransform: 'lowercase'}}
                    onClick={editQuestions}
                >
                    Edit Questions
                </Button>
                <SliderTable
                    handleK={handleTopK}
                    handleP={handleTopP}
                    handleT={handleTemp}
                    handleMT={handleMaxTokens}
                />
                <Button
                    variant='contained'
                    color="success"
                    disableElevation={true}
                    sx={{borderRadius: 0, textTransform: 'lowercase'}}
                    onClick={generatePrompt}
                >
                    Generate Prompt
                </Button>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                height: '80%',
                width: '100%'
            }}>
                <DataGridPro
                    rows={queryRows}
                    columns={queryColumns}
                    pagination={true}
                    autoHeight={false}
                    autoPageSize={false}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(id) => {
                        let select = id.map((sel) => queryRows.find((q) => q.id === sel))
                        setQuerySelect(select)
                    }}
                    getDetailPanelContent={panelContent}
                    getDetailPanelHeight={() => 'auto'}
                />
                <DataGrid
                    autoPageSize={false}
                    autoHeight={false}
                    rows={brandLabelRows}
                    columns={brandLabelColumns}
                    pageSizeOptions={[5, 10, 25]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(id) => {
                        let select = id.map((sel) => brandLabelRows.find((q) => q.id === sel))
                        setBrandLabelSelect(select)
                    }}
                />
            </div>
        </div>
    )
}

export default GeneratePrompt