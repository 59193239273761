import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

import { Button, Typography } from '@mui/material'
import { DataGrid, GridCellEditStopReasons } from '@mui/x-data-grid';
import question_list from '../configs/questions_and_brandmessage.json'

import { API } from 'aws-amplify'
import { GridCellExpand } from './grid_cell_expand'

// const apiName = "arch-api"
const apiName = "ARCH-Keystone-API"



function renderCellExpand(params) {
    return (
        <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    );
}

export function ClientIntakeGrid({clientProps: props}){
    const clientInfo = props ? props : null
    const questionArray = []
    // const clientAnswerArray = []
    // const archInsightArray = []  
    const route = useNavigate()  
    const [ questionList, setQuestionList ] = useState([])
    // const [ tempValue, setTempValue ] = useState()

    const intakeColumns = [
        { field: 'id', headerName: 'ID'},
        { field: 'question', headerName: 'List of Questions', width: '250', renderCell: renderCellExpand },
        { field: 'answer', headerName: 'Client Answers', editable: true, width: '500', renderCell: renderCellExpand },
        { field: 'insight', headerName: 'ARCH Insight', editable: true, width: '500', renderCell: renderCellExpand}
    ]

    useEffect(() => {
        const listArray = []
        question_list.forEach((list) => {
        list.queries.forEach((query) => {
            query.questions.forEach((q) => {
                listArray.push({ id: q.id, question: q.question, answer: "", insight: ""})
            })
            })
        })

        if(clientInfo.qa !== undefined) {
            let isAnsweredArray = Array.from(clientInfo.qa)
            listArray.forEach((e) => {
                isAnsweredArray.forEach((qa) => {
                    if(e.question === qa.question && (!!qa.answer || !!qa.insight)) {
                        listArray.splice(e.id-1, 1, {id: e.id, question: qa.question, answer: qa.answer !== '' ? qa.answer : "", insight: qa.insight !== '' ? qa.insight : ""})
                    }
                })
            })
            console.log(listArray)
            setQuestionList(listArray)
        } else {
            console.log(listArray)
            setQuestionList(listArray)
        }
    }, [clientInfo])

    const updateAnswersAndInsights = () => {
        if(clientInfo !== null) {
            console.log(clientInfo.clientId)
            
            if(clientInfo.clientId === null) {
                const params ={
                    name: clientInfo.name,
                    tone: clientInfo.tone
                }
                const saveClientConfigs = {
                    queryStringParameters: params
                }
                const saveClientPath = "/client/create"
                const clientId = [];

            API.post(apiName, saveClientPath, saveClientConfigs)
            .then((res) => {
                console.log(res)
                let clientInfo = res.split(/:|;/)
                clientId.push(parseInt(clientInfo[1].trim()))
                }).catch((error) => {
                    console.log(error.response);
                }).then(() => {
                
                const saveQAConfigs = {
                    body: {
                        questionAnswers: questionList.map((qa) =>{
                            return {
                                client_id: clientId[0],
                                clientTone: null,
                                question: qa.question,
                                answer: qa.answer,
                                insight: qa.insight,
                                ordinal: qa.id
                            }
                        }),
                    },
                    response: true
                }
            
                const saveQAPath = "/qa/create"
            
                API.post(apiName, saveQAPath, saveQAConfigs)
                    .then((response) => {
                        console.log(response)
                        route("/")
                    }).catch((error) => {
                        console.log(error)
                        route("/")
                    })
                })
        }else {
            const saveQAConfigs = {
                body: {
                    questionAnswers: questionList.map((qa) =>{
                        return {
                            client_id: clientInfo.clientId,
                            question: qa.question,
                            answer: qa.answer,
                            insight: qa.insight,
                            ordinal: qa.id
                        }
                    }),
                },
                response: true
            }

            const saveQAPath = "/qa/create"
    
            API.post(apiName, saveQAPath, saveQAConfigs)
                .then((response) => {
                    console.log(response)
                    route("/")
                }).catch((error) => {
                    console.log(error);
                    route("/")
                });
        }
    }
    
    }
    
    // FIXES FOCUS OUT BUG
    // const setValueForFocusOut = (cellParams, cellEvent) => {
    //     let testValue = cellEvent.target.value
    //     console.log(cellParams, cellEvent)
    //     if(cellParams.value !== "") {}
    //         console.log(testValue)
    // }

    const setAnswersInsights = (cellParams, cellEvent) => {
        let tempUpdateQuestionsArray = Array.from(questionList)
        const updatedArray = questionArray.find((questionElement) => {
            return questionElement.ordinal === cellParams.id
        })
        if(!updatedArray) { 
            questionArray.push({ question: cellParams.row.question, ordinal: cellParams.id })
        }

        if(cellParams.field === 'answer') {
            let index = tempUpdateQuestionsArray.findIndex((e) => e.id === cellParams.id)
            tempUpdateQuestionsArray.splice(index, 1, {id: cellParams.id, question: tempUpdateQuestionsArray[index].question, answer: cellEvent.target.value, insight: tempUpdateQuestionsArray[index].insight})
            setQuestionList(tempUpdateQuestionsArray)
        } else if(cellParams.field === 'insight') {
            let index = tempUpdateQuestionsArray.findIndex((e) => e.id === cellParams.id)
            tempUpdateQuestionsArray.splice(index, 1, {id: cellParams.id, question: tempUpdateQuestionsArray[index].question, answer:tempUpdateQuestionsArray[index].answer, insight: cellEvent.target.value})
            setQuestionList(tempUpdateQuestionsArray)
        }
        console.log(questionArray, tempUpdateQuestionsArray)
    }

    return(
        <div>
            <Typography variant='body2'> *Press "Enter" or "Tab" To Save Answers To Draft* </Typography>
            <DataGrid
                rows={questionList}
                rowHeight={100}
                columns={intakeColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 10,
                        }
                    }
                }}
                pageSizeOptions={[5, 10, 25]}
                onCellEditStop={(params, e) => setAnswersInsights(params, e) }
            />
            <div>
                <Button onClick={updateAnswersAndInsights}> Save Answers </Button>
            </div>
        </div>
    )
}