import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@aws-amplify/ui-react/styles.css'

import { Amplify, Auth } from 'aws-amplify'
import config from './aws-exports'
import { ThemeProvider } from '@aws-amplify/ui-react'

Auth.configure(config)
Amplify.configure({
  // OPTIONAL - if your API requires authentication 
    API: {
        endpoints: [
          {
              name: "arch-rest-api",
              endpoint: "https://7z9v3fcu07.execute-api.us-west-2.amazonaws.com/dev",
          },
          {
            name: "arch-api",
            endpoint: "https://5pnvu90tx3.execute-api.us-west-2.amazonaws.com/dev"
          },
          {
            name: "ARCH-Keystone-API",
            endpoint: "https://41g1ugeaq3.execute-api.us-west-2.amazonaws.com/prod"
          }
        ]
    },
    Storage: {
        AWSS3: {
            isObjectLockEnabled: false,
            region: 'us-west-2',
            bucket: 'arch-ui-storage-52def31535010-staging'
        }
    }
});

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
