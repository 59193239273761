import {Box, Button, Container, Modal, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, {useEffect, useState} from "react";

const modalStyles = {
	button: {},
	modal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	}
}

const PlagiarismCheckerModal = ({open, setOpen, response, setResponse}) => {
	const [isResponseReady, setIsResponseReady] = useState(!!response)
	const [parsedResponses, setParsedResponses] = useState(null)
	
	useEffect(() => {
		if (!!response) {
			setIsResponseReady(true)
			setParsedResponses(response.split('\n'))
		} else {
			setIsResponseReady(false)
			setParsedResponses(null)
		}
	}, [response]);
	
	return(
		<div>
			<Modal
				open={open}
				sx={{
					...modalStyles.modal,
					'& .MuiContainer-root': {
						height: '75vh',
						width: '75vw'
					}
				}}
			>
				<Container sx={modalStyles.modal}>
					<Typography id="modal-modal-title" variant='h3'>Plagiarism Checker</Typography>
					{!!isResponseReady
						? <div style={{
							border: '1px solid rgba(0, 0, 0, 0.25)',
							overflowY: 'scroll',
							height: '80%',
							width: '100%'
						}}>
							{parsedResponses.map((parsed) => {
								return (
									<Typography>
										{parsed.trim()}
									</Typography>
								)
							})}
						</div>
						: <CircularProgress />
					}
					<Box>
						<Button onClick={() => {
							setOpen(false)
							setResponse(null)
						}}> Close </Button>
					</Box>
				</Container>
			</Modal>
		</div>
	)
}

export default PlagiarismCheckerModal