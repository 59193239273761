import React from "react";
import {Button, Card, CardActions, CardContent, Divider, TextField, Typography} from "@mui/material";


const PromptResponseCard = ({isDisabled, promptResponse, savePrompt, score, plagiarismCheck, setScoreReleaseButton}) => {
	let responseStyle = {
		alignItems: 'center',
		border: '1px solid rgba(0, 0, 0, 0.25)',
		display: 'flex',
		flexDirection: 'row',
		height: '20%',
		justifyContent: 'flex-start'
	}
	
	return (
		<div className='page'>
			<Card sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				justifyContent: 'flex-start',
				overflowX: 'hidden',
				overflowY: 'scroll',
				padding: '0px',
				paddingBlock: '0px',
				paddingBottom: '0px',
				paddingLeft: '0px',
				paddingRight: '0px',
				paddingTop: '0px',
				width: '100%'
			}}>
				<CardContent>
					<div style={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
					}}>
						<Card sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							height: '20%',
							justifyContent: 'center',
							overflowX: 'hidden',
							overflowY: 'scroll',
							width: '100%'
						}}>
							<CardContent >
								<Typography variant="h6">Reasoning</Typography>
								<Typography variant='body1'>{promptResponse.reasoning}</Typography>
							</CardContent>
						</Card>
						<Divider/>
						<Card sx={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
							height: '80%',
							justifyContent: 'center',
							overflowX: 'hidden',
							overflowY: 'scroll',
							width: '100%'
						}}>
							<CardContent>
								<div style={{
									height: '10%',
									width: '100%'
								}}>
									<Typography variant="h6">Responses</Typography>
								</div>
								<div style={{
									height: '90%',
									width: '100%'
								}}>
									{promptResponse.responses.map((response) => {
										return (
											<div style={responseStyle}>
												<table style={{
													height: '100%',
													width: '100%'
												}}>
													<thead>
														<tr style={{
															alignItems: 'center',
															display: 'flex',
															flexDirection: 'row',
															height: '100%',
															justifyContent: 'center',
															width: '100%'
														}}>
															<td style={{
																alignItems: 'center',
																display: 'flex',
																flexDirection: 'row',
																height: '100%',
																justifyContent: 'flex-start',
																width: '75%'
															}}>
																<Typography variant='body1'> {String(response)} </Typography>
															</td>
															<td style={{
																alignItems: 'center',
																display: 'flex',
																flexDirection: 'column',
																height: '100%',
																justifyContent: 'center',
																width: '25%'
															}}>
																<Button
																	onClick={() => plagiarismCheck(String(response))}
																	sx={{
																		height: '5vh',
																		width: '20vw'
																	}}
																>
																	Check for Plagiarism
																</Button>
															</td>
														</tr>
													</thead>
												</table>
											</div>
										)
									})}
								</div>
							</CardContent>
						</Card>
					</div>
				</CardContent>
				<CardActions>
					<TextField
						label="Score 1-10"
						variant="standard"
						value={score}
						onChange={(e) => setScoreReleaseButton(e)} />
					<Button disabled={isDisabled} onClick={savePrompt}> Save Prompt </Button>
				</CardActions>
			</Card>
		</div>
	)
}

export default PromptResponseCard