import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ClientDataGrid } from '../components/client_data_grid'
import {Alert, Box, Button, Collapse, Container, IconButton, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {API} from "aws-amplify";
import { version } from "react";

const styles = {
    button: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: '10px'
    }
}

const Main = () => {
    // const alertProps = useLocation()
    const routeToAddClient = useNavigate()        
    const [ openAlert, setOpenAlert ] = useState(false)
    const [ alertConfigs, setAlertConfigs ] = useState({ alert: "", severity: ""})
    const [ clientList, setClientList ] = useState([])


    useEffect(() => {
        // const settings = alertProps.state !== null ? alertProps.state : ""
        // console.log(alertProps)
        // console.log(settings)
        // setOpenAlert(true)
        // setAlertConfigs(settings !== "" ? { alert: settings.alert, severity: settings.severity} : "")
        // let apiName = "arch-api"
        let apiName = "ARCH-Keystone-API"
        let path = "/client"
        API.get(apiName, path)
            .then((res) => {
                setClientList(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
 
    return(
        <div className='page'>
            <Box sx={{'& > legend': { mt: 2}}}>
                {/* <Collapse in={openAl}>
                    <Alert severity={alertConfigs.severity} action={<IconButton onClick={() => { setOpenAlert(false)}}><CloseIcon></CloseIcon></IconButton>}>
                         { alertConfigs.alert}
                    </Alert>
                </Collapse> */}
            </Box>
            <Container>
                <Box sx={styles.button}>
                    <Button
                        variant='contained'
                        color="error"
                        disableElevation={true}
                        sx={{ borderRadius: 0, textTransform: 'lowercase' }}
                        onClick={() => {
                            routeToAddClient('/add_client')
                        }}
                    > 
                        Add Client +
                    </Button>
                </Box>
                <ClientDataGrid clientList={clientList}/>
            </Container>
            <Typography>{process.env.REACT_APP_VERSION}</Typography>
        </div>
    )
}

export default Main;