import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'

import {Header} from './components/header'
import Main from './pages/main'
import AddClient from './pages/add_client'
import GeneratePrompt from './pages/generate_prompt'
import PromptResponse from './pages/prompt_response'
import ChatHistory from './pages/chat_history'


function App({ signOut, user}) {
  // const config = user.signInUserSession.accessToken.jwtToken

  return (
    <div className="App">
        <Router>
          <Header signOut={signOut} />
          <Routes>
            <Route element={ <Main /> } path="/" />
            <Route element={ <AddClient /> } path="/add_client" />
            <Route element={ <GeneratePrompt /> } path="/generate_prompt" />
            <Route element={ <PromptResponse /> } path="/prompt_response" />
            <Route element={ <ChatHistory /> } path="/chat_history" />
          </Routes>
        </Router>
    </div>
  );
}
export default withAuthenticator(App);
