import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import { API } from 'aws-amplify'
import PromptResponseCard from "../components/prompt_response_card";
import PlagiarismCheckerModal from "../components/plagiarism_checker_modal";

// const apiName = "arch-api";
const apiName = "ARCH-Keystone-API"

const PromptResponse = () => {
    const route = useNavigate()
    const location = useLocation()
    const promptInfo = location.state

    const [ promptResponse, setPromptResponse ] = useState({
        answer: '',
        reasoning: '',
        responses: [],
    })
    const [ score, setScore ] = useState()
    const [ isDisabled, setIsDisabled ] = useState(true)
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ plagiarismResponse, setPlagiarismResponse ] = useState(null)
    
    const generatePrompt = async (apiName, path, state) => {
    
        API.post(apiName, path, state)
            .then((res) => {
                let response = res.data.answer
                let apiResponsePattern = /Reasoning:(?<Reasoning>[\s\S]+)\n\nResponses:(?<Responses>[\s\S]+)/
                let apiResponseMatch = response.match(apiResponsePattern)
                
                if (apiResponseMatch) {
                    let reasoningGroup = apiResponseMatch.groups.Reasoning.trim()
                    let responsesGroup = apiResponseMatch.groups.Responses.trim()
                    let numberedResponsePattern = /\n\d+./
                    let responseList = responsesGroup.split(numberedResponsePattern)
                    let tempList = []
                    let tempItem = null
                    let itemInProgress = false

                    responseList.forEach((response) => {
                        let trimmedResponse = response.trim().concat('\n\n')
                        let containsBml = false
                        console.log(response)
                        promptInfo.generate.brandMessageLabels.forEach((label) => {
                            if (response.includes(label)) {
                                containsBml = true
                            }
                        })
                        if (containsBml && !itemInProgress) {
                            console.log('containsBml && !itemInProgress')
                            tempList.push(trimmedResponse)
                        } else if (containsBml && itemInProgress) {
                            console.log('containsBml && itemInProgress')
                            tempList.push(tempItem)
                            tempList.push(trimmedResponse)
                            itemInProgress = false
                            tempItem = null
                        } else if (!containsBml && itemInProgress) {
                            console.log('!containsBml && itemInProgress')
                            tempItem = tempItem.concat(trimmedResponse)
                        } else {
                            console.log('!containsBml && !itemInProgress')
                            itemInProgress = true
                            tempItem = tempList.pop()
                            tempItem = tempItem.concat(trimmedResponse)
                        }
                    })

                    if (itemInProgress) {
                        tempList.push(tempItem)
                        itemInProgress = false
                        tempItem = null
                    }

                    responseList = tempList
                    
                    // Remove extra numbers at beginning of responsePattern
                    let temp = responseList[0].slice(2, responseList[0].length)
                    responseList.splice(0, 1, temp)
                    
                    let counter = 1
                    let numberedList = responseList.map((response) => {
                        return String(counter++) + ": " + response
                    })
                    
                    setPromptResponse({
                        answer: response,
                        reasoning: reasoningGroup,
                        responses: numberedList,
                    })
                } else {
                    console.log("No match found.")
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const savePrompt = () => {
        let response_body = {
            chat: {
                client_id: promptInfo.save.clientId,
                qa_ids: promptInfo.save.qaIds,
                brand_messaging_label_ids: promptInfo.save.bmlIds,
                tone: promptInfo.save.tone,
                response: promptResponse.answer,
                foundation_model_id: "anthropic.claude-instant-v1",
                max_tokens: promptInfo.generate.queryStringParameters.maxTokensToSample,
                temperature: promptInfo.generate.queryStringParameters.temperature,
                top_k: promptInfo.generate.queryStringParameters.topK,
                top_p: promptInfo.generate.queryStringParameters.topP,
                score: parseInt(score),
            }
        }
        let configs = {
            body: response_body,
            response: true
        }
        let path = "/chat-history/create"

        API.post(apiName, path, configs)
            .then((res)=>{
                console.log(res)
            }).catch((error) => {
                console.log(error)
            })    

        route(-1)
    }

    const checkForPlagiarism = async (response) => {
        setModalOpen(true)
        let path = "/plagiarism-checker"
        let params = {
            maxTokensToSample: promptInfo.generate.queryStringParameters.maxTokensToSample
            // maxTokensToSample: 512
        }
        let response_body =  {
            brandMessageLabels: promptInfo.generate.brandMessageLabels,
            campaignBlurbs: response
        }
        let configs = {
            body: response_body,
            queryStringParameters: params
        }
        API.post(apiName, path, configs)
        .then((res)=>{
            console.log(res)
            let response = res.answer
            setPlagiarismResponse(response)
        }).catch((error) => {
            console.log(error)
        })    
    }

    useEffect(() => {
        let path = "/chat"
        generatePrompt(apiName, path, promptInfo.generate)
            .then(() => {
                console.log("Prompt Generated")
            })
            .catch(() => {
                console.log("Error Generating Prompt")
            })
    }, [promptInfo])

    const setScoreReleaseButton = (e) => {
        setScore(e.target.value)
        setIsDisabled(false)
    }
    
    let pageStyle = {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
    }
    
    let containerStyle = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    }

    return (
        <div style={pageStyle}>
            <Container sx={containerStyle}>
                <PlagiarismCheckerModal
                    open={modalOpen}
                    setOpen={setModalOpen}
                    response={plagiarismResponse}
                    setResponse={setPlagiarismResponse}
                />
                {!!promptResponse.answer
                    ? <PromptResponseCard
                        isDisabled={isDisabled}
                        promptResponse={promptResponse}
                        score={score}
                        setScoreReleaseButton={setScoreReleaseButton}
                        savePrompt={savePrompt}
                        plagiarismCheck={checkForPlagiarism}
                    />
                    : <CircularProgress />
                }
            </Container>
        </div>
    )
}

export default PromptResponse