import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { API } from 'aws-amplify';

const modalStyles = {
  modal: {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    bottom: '12.5%',
    boxShadow: 24,
    left: '12.5%',
    right: '12.5%',
    p: 4,
    position: 'absolute',
    top: '12.5%',
  },
};

const apiName = "ARCH-Keystone-API";

const ChatHistory = () => {
  const [duplArray, setDuplArray] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [openChatModal, setOpenChatModal] = useState(false);
  const [chatArray, setChatArray] = useState([]);
  const [responseList, setResponseList] = useState([]);
  const [brandMessageLabels, setBrandMessageLabels] = useState([]);

  useEffect(() => {
    const fetchBrandMessageLabels = async () => {
      try {
        const response = await API.get(apiName, "/brand-messaging-label", {});
        setBrandMessageLabels(response);
      } catch (error) {
        console.error('Unable to retrieve BMLs:', error);
      }
    };
    fetchBrandMessageLabels();
  }, []);

  useEffect(() => {
    if (brandMessageLabels.length > 0) {
      brandMessageLabels.forEach((bml) => {
        console.log(bml);
      });
    }
  }, [brandMessageLabels]);

  const clientColumns = [
    { field: 'name', headerName: 'Client Name', width: 200 },
    { field: 'history', headerName: 'History Saved?', width: 150, type: 'boolean' },
  ];

  const historyColumns = [
    { field: 'tone', headerName: 'Client Tone', width: 200 },
    { field: 'created_date', headerName: 'Created Date', width: 200 },
  ];

  const getChatHistory = async (id) => {
    const historyPath = "/chat-history";
    const params = { client_id: id };
    const configs = { queryStringParameters: params };

    try {
      const res = await API.get(apiName, historyPath, configs);
      setChatArray(res);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };

  const parseResponse = (response) => {
    const responsePattern = /Reasoning:(?<Reasoning>[\s\S]+)\n\nResponses:(?<Responses>[\s\S]+)/;
    const responseMatch = response.match(responsePattern);

    if (responseMatch) {
      const reasoningGroup = responseMatch.groups.Reasoning.trim();
      let responsesGroup = responseMatch.groups.Responses.trim();
      const numberedResponsePattern = /\n\d+./;
      let responses = responsesGroup.split(numberedResponsePattern);
      let tempList = [];
      let tempItem = null;
      let itemInProgress = false;

      responses.forEach((response) => {
        const trimmedResponse = response.trim().concat('\n\n');
        let containsBml = false;

        brandMessageLabels.forEach((label) => {
          if (response.includes(label.description)) {
            containsBml = true;
          }
        });

        if (containsBml && !itemInProgress) {
          tempList.push(trimmedResponse);
        } else if (containsBml && itemInProgress) {
          tempList.push(tempItem);
          tempList.push(trimmedResponse);
          itemInProgress = false;
          tempItem = null;
        } else if (!containsBml && itemInProgress) {
          tempItem = tempItem.concat(trimmedResponse);
        } else {
          itemInProgress = true;
          tempItem = tempList.pop();
          tempItem = tempItem.concat(trimmedResponse);
        }
      });

      if (itemInProgress) {
        tempList.push(tempItem);
        itemInProgress = false;
        tempItem = null;
      }

      responses = tempList;

      // Remove extra numbers at beginning of responsePattern
      const temp = responses[0].slice(2, responses[0].length);
      responses.splice(0, 1, temp);

      // Manually number the responses
      let counter = 1;
      const numberedList = responses.map((response) => {
        return String(counter++) + ": " + response;
      });

      setResponseList(numberedList);
      setOpenChatModal(true);
    } else {
      console.error('Unable to parse chat history');
    }
  };

  const SelectChatModal = ({ responses }) => (
    <div style={{
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%'
    }}>
      <Modal
        open={openChatModal}
        onClose={() => setOpenChatModal(false)}
      >
        <Container sx={modalStyles.modal}>
          {responses.map((response, index) => (
            <Typography key={index} variant='body1'>{response}</Typography>
          ))}
          <Box>
            <Button onClick={() => setOpenChatModal(false)}>Close</Button>
          </Box>
        </Container>
      </Modal>
    </div>
  );

  const DisplayHistoryList = () => (
    <div>
      <SelectChatModal responses={responseList} />
      <DataGrid
        rows={historyList}
        columns={historyColumns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10 }
          }
        }}
        pageSizeOptions={[10, 15, 25]}
        disableRowSelectionOnClick
        onRowClick={(e) => parseResponse(e.row.response)}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          }
        }}
      />
    </div>
  );

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const res = await API.get(apiName, "/client");
        setDuplArray(res);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    const rowArray = [];
    const histArray = [];

    duplArray.forEach((client) => {
      rowArray.push({ id: client.id, name: client.name, history: true });
    });

    if (chatArray.length !== 0) {
      chatArray.forEach((chat) => {
        histArray.push({ id: chat.id, tone: chat.tone, created_date: chat.created_at, response: chat.response });
      });
    }

    setHistoryList(histArray);
    setClientList(rowArray);
  }, [duplArray, chatArray]);

  return (
    <div>
      <Typography variant='h2' style={{ display: 'flex', padding: '10px' }}>Client Chat History</Typography>
      <Grid container justifyContent="center" spacing={5} padding={5}>
        <Grid item>
          <DataGrid
            rows={clientList}
            columns={clientColumns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 }
              }
            }}
            pageSizeOptions={[10, 15, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(selectionModel) => {
              if (selectionModel.length > 0) {
                getChatHistory(selectionModel[0]);
              } else {
                setChatArray([]); // Clear chatArray when no rows are selected
                setHistoryList([]); // Clear historyList when no rows are selected
              }
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: { disableToolbarButton: true },
                printOptions: { disableToolbarButton: true }
              }
            }}
          />
        </Grid>
        <Grid item>
          {historyList.length !== 0 && <DisplayHistoryList />}
        </Grid>
      </Grid>
    </div>
  );
};

export default ChatHistory;
