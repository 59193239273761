import {Button, Modal, Paper, Typography} from "@mui/material";

const DeleteModal = ({ deletionTargetId, open, onClickYes, onClickNo }) => {
	return (
		<Modal open={open} sx={{
			bottom: '40%',
			display: 'flex',
			flexDirection: 'column',
			height: '20%',
			left: '40%',
			position: 'absolute',
			right: '40%',
			top: '20%',
			width: '20%'
		}}>
			<Paper sx={{
				alignItems: 'center',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				border: '1px solid rgba(0, 0, 0, 0.25)',
				color: 'rgba(0, 0, 0, 1)',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%'
			}}>
				<div style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'column',
					height: '50%',
					justifyContent: 'center',
					width: '75%',
				}}>
					<Typography>{'Client ID: ' + deletionTargetId}</Typography>
					<Typography>Are you sure you want to delete?</Typography>
				</div>
				<div style={{
					alignItems: 'center',
					display: 'flex',
					flexDirection: 'row',
					height: '50%',
					justifyContent: 'space-evenly',
					width: '75%',
				}}>
					<Button variant='contained' onClick={onClickNo}>
						No
					</Button>
					<Button variant='contained' onClick={onClickYes}>
						Yes
					</Button>
				</div>
			</Paper>
		</Modal>
	)
}

export default DeleteModal