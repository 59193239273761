import React from 'react'
import { Box, FormControlLabel, Switch, Typography, TextField } from '@mui/material'


export function NewClient({nameVal, toneVal, nameOnChange, toneOnChange, acButton, trigger, isSwitch}){
    return(
        <div>
            <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                <Typography> Enter Client Name: </Typography>
                <TextField 
                    id='outlined-basic' 
                    required 
                    value={nameVal}
                    onChange={(e) => nameOnChange(e.target.value)} 
                />
                <Typography> Enter Client Tone: </Typography>
                <TextField 
                    id='outlined-basic' 
                    required 
                    value={toneVal}
                    onChange={(e) => toneOnChange(e.target.value)}  
                />
                { acButton }
            </Box>
            <Box display='flex' alignItems='center'>
            <FormControlLabel 
                control={
                    <Switch 
                        checked={isSwitch} 
                        onChange={(e) => trigger(e)}/>
                } 
                label="Add Client Only" />
        </Box>
        </div>
    )
}
