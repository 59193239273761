import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Button, Container, FormControlLabel, Switch, TextField, Typography } from '@mui/material'

import { API } from 'aws-amplify'

import { updateClientTone } from '../components/global_endpoints'
import { ClientIntakeGrid } from '../components/client_intake_grid'
import { NewClient } from '../components/add_client_components'
import S3BucketSubmitButton from '../components/s3_bucket_submit_button'

const AddClient = () => {
    const route = useNavigate()
    const data = useLocation()
    const clientData = data.state
    const [ clientName, setClientName ] = useState('')
    const [ clientTone, setClientTone ] = useState('')
    const [ alert, setAlert ] = useState({ trigger: true, alert: "Client Successfully Saved", severity: "success"})
    const [ saveQAInfo, setSaveQAInfo ] = useState({
        clientId: null,
        name: null,
        tone: null
    })
    const [ switchSettings, setSwitchSettings ] = useState(false)
    const [ isDisabled, setIsDisabled ] = useState(true)

    const addNewClient = () => {
        const params = {
            name: clientName,
            tone: clientTone,
        }
        const configs = { 
            queryStringParameters: params
        }

        // const apiName = "arch-api"
        const apiName = "ARCH-Keystone-API"
        const path = "/client/create"
        API.post(apiName, path, configs)
            .then((res) => {
                console.log(res)
            }).catch((error) => {
                console.log(error.response);
                setAlert({ trigger: true, alert: "Error Adding Client, Please Try Again", severity: "error"})
            })
        route("/", { state: alert })
    }

    const triggerSwitch = (e) => {
        setSwitchSettings(e.target.checked)
    }

    function AddClientButton() {
        return(
            <Button 
                id='submit'
                variant='contained'
                color="error"
                // disabled={isDisabled}
                disableElevation={true}
                sx={{ borderRadius: 0, textTransform: 'lowercase'}}
                onClick={addNewClient}
            >
                Add Client    
            </Button>
        )
    }

    function UpdateClient() {
        let tone = ""
        if(clientData) { 
            console.log(clientData)
            const clientId = clientData.clientDetails.clientId
            const clientName = clientData.from ? clientData.clientDetails.clientName : clientData.clientName
            const clientCurrentTone = clientData.from  && clientData.tone !== "" ?  clientData.clientDetails.tone : clientData.tone
            return (
                <div>
                    <Box display='flexbox' alignItems='center' justifyContent='center' flexDirection='column'>
                        <Typography> Client Name: </Typography>
                        <Typography variant='h4'sx={{textTransform: 'lowercase'}}> { clientName } </Typography>
                        <Typography>  Client Tone: </Typography>
                        <TextField label="Update Tone Here" defaultValue={clientCurrentTone} variant='standard' onChange={(e) => tone=e.target.value}/>
                        <Button variant='success' onClick={() => {
                            console.log('clientId: ' + clientId)
                            console.log('tone: ' + tone)
                            updateClientTone(clientId, tone)
                        }}> Update Tone </Button>
                        { hideAddClientButton }
                    </Box>
                </div>
            )
        }
    }

    const hideIntakeGrid = !switchSettings ? <ClientIntakeGrid clientProps={saveQAInfo} /> : ""
    const hideAddClientButton = switchSettings ? <AddClientButton /> : ""
    const isNewClient = clientData === null
        ? <NewClient
            nameVal={clientName}
            toneVal={clientTone}
            nameOnChange={setClientName}
            toneOnChange={setClientTone}
            acButton={hideAddClientButton}
            trigger={triggerSwitch}
            isSwitch={switchSettings}
        /> : <UpdateClient />

    useEffect(() => {
        if (clientData !== null) {
          setSaveQAInfo( 
            clientData.from ?
            {
                ...saveQAInfo,
                clientId: clientData.clientDetails.clientId, 
                qa:  clientData.qaDetails
            } 
                : 
            {
                ...saveQAInfo,
                clientId: clientData.clientId, 
            })
        } else {
            setSaveQAInfo({
                ...saveQAInfo,
                name: clientName,
                tone: clientTone
            })
        }
    }, [clientName, clientTone, clientData])

    return(
        <Container>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                flexDirection: 'row'
            }}>
                <Typography variant='h2' style={{display: 'flex'}}>
                    Client Intake Form
                </Typography>
                <S3BucketSubmitButton/>
            </div>
            { isNewClient }
            { hideIntakeGrid }
        </Container>
    )
}

export default AddClient;