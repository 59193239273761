import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import {RemoveCircleOutline} from "@mui/icons-material";
import { API } from 'aws-amplify'
import DeleteModal from "./delete_modal";

// const apiName = "arch-api"
const apiName = "ARCH-Keystone-API"

export function ClientDataGrid({ clientList }) {
    const route = useNavigate()
    const [ clientRows, setClientRows ] = useState([])
    const [ deletionTargetId, setDeletionTargetId ] = useState(null)
    const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false)
    
    const apiDeleteCall = async () => {
        let newRows = clientRows.filter((row) => (row.id !== deletionTargetId))
        setClientRows(newRows)
        let queryParams = {
            id: deletionTargetId
        }
        let configs = {
            queryStringParameters: queryParams
        }
        API.del(apiName, '/client/delete', configs)
            .then((response) => {
                console.log('Deleted')
                console.log(response)
            })
            .catch((reason) => {
                console.log('Not Deleted')
                console.log(reason)
            })
    }
    
    const getCellActions = (params) => {
        return ([
            <GridActionsCellItem
                color={'error'}
                icon={<RemoveCircleOutline/>}
                label={'Delete'}
                onClick={() => {
                    setDeletionTargetId(params.id)
                    setIsDeleteModalOpen(true)
                }}
            />
        ])
    }
    
    const onClickNo = () => {
        setIsDeleteModalOpen(false)
        setDeletionTargetId(null)
    }
    
    const onClickYes = () => {
        apiDeleteCall().then(() => {
            setIsDeleteModalOpen(false)
        }).catch((reason) => {
            console.log(reason)
            setIsDeleteModalOpen(false)
            setDeletionTargetId(null)
        })
    }

    const routeToGeneratePrompt = (e) => {
        const data = {
            clientId: e.id,
            clientName: e.row.name,
            createdDate: e.row.created_date,
            tone: e.row.tone
        }

        route('/generate_prompt', {state: data})
    }

    const clientColumns = [
        { field: 'name', headerName: 'Client Name', width: '200'},
        { field: 'created_date', headerName: 'Date Created', width: '200' },
        { field: 'tone', headerName: 'Current Tone', width: '200' },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: '200',
            //editable: true,
            type: "singleSelect",
            valueOptions: [ 
                {value: "new", label: "Just Added"}, 
                {value: "opened", label: "In Progress"}, 
                {value: "review", label: "In Review"}, 
                {value: "completed", label: "Completed"}
            ],
        },
        { field: 'files', headerName: 'Files Added?', width: '200', type: 'boolean' },
        { editable: false, field: 'delete', getActions: getCellActions, type: 'actions', width: 50 }
    ]
    
    useEffect(() => {
        let rowArray = []
        try {
            clientList.forEach((client) => {
                rowArray.push({
                    id: client.id,
                    name: client.name,
                    created_date: client.created_at,
                    tone: client.tone,
                    status: "Created",
                    file: true
                })
            })
        } catch(e) {
            console.log(e)
        }
        setClientRows(rowArray)
    }, [clientList])
        
    return( 
        <div>
            <DataGrid
                rows={ clientRows }
                columns={ clientColumns }
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10
                        }
                    }
                }}
                pageSizeOptions={[10, 15, 25]}
                onRowClick={(e) => routeToGeneratePrompt(e)}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: { disableToolbarButton: true },
                        printOptions: { disableToolbarButton: true }
                    }
                }}
                sx={{
                    height: '100%',
                    width: '100%'
                }}
            />
            <DeleteModal
                deletionTargetId={deletionTargetId}
                open={isDeleteModalOpen}
                onClickYes={onClickYes}
                onClickNo={onClickNo}
            />
        </div>
    )
}
