import React, { useState} from 'react'
import { Box, Card, CardContent, Grid, Slider, TextareaAutosize, Typography } from '@mui/material'

export function SliderTable({ handleK, handleP, handleT, handleMT }) {
    const [ kHover, setKHover ] = useState(false)
    const [ pHover, setPHover ] = useState(false)
    const [ tempHover, setTempHover ] = useState(false)
    const [ mtHover, setMTHover ] = useState(false)

    const kDef = <Typography variant='body2' sx={{wordBreak: 'break-word'}}> This Slider Controls Token Amount (higher number = more diversity) </Typography>
    const pDef = <Typography variant='body2' sx={{wordBreak: 'break-word'}}> This Slider Controls Probability (lower number = more probable) </Typography>
    const tDef = <Typography variant='body2' sx={{wordBreak: 'break-word'}}> This Slider Controls Randomness (lower number = less random) </Typography>
    const mtDef = <Typography variant='body2' sx={{wordBreak: 'break-word'}}> This Slider Controls Message Response Size </Typography>

    let sliderStyle = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    }
   
    return (
        <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyContent: 'space-evenly',
            width: '100%'
        }}>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                width: '20%'
            }}>
                <div style={sliderStyle} onMouseEnter={() => setKHover(true)} onMouseLeave={() => setKHover(false)}>
                    {kHover ? kDef : <Typography variant='subtitle1'> Top K</Typography>}
                </div>
                <Slider
                    aria-label="Top K"
                    defaultValue={250}
                    valueLabelDisplay="auto"
                    step={50}
                    marks
                    min={0}
                    max={500}
                    onChange={(e) => handleK(e)}
                />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                width: '20%'
            }}>
                <div style={sliderStyle} onMouseEnter={() => setPHover(true)} onMouseLeave={() => setPHover(false)}>
                    {pHover ? pDef : <Typography variant='subtitle1'> Top P</Typography>}
                </div>
                <Slider
                    aria-label="Top P"
                    defaultValue={1.0}
                    valueLabelDisplay="auto"
                    step={0.1}
                    marks
                    min={0.0}
                    max={1.0}
                    onChange={(e) => handleP(e)}
                />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                width: '20%'
            }}>
                <div style={sliderStyle} onMouseEnter={() => setTempHover(true)} onMouseLeave={() => setTempHover(false)}>
                    {tempHover ? tDef : <Typography variant='subtitle1'> Temperature </Typography>}
                </div>
                <Slider
                    aria-label="Temperature"
                    defaultValue={0.5}
                    valueLabelDisplay="auto"
                    step={0.1}
                    marks
                    min={0.0}
                    max={1.0}
                    onChange={(e) => handleT(e)}
                />
            </div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                width: '20%'
            }}>
                <div style={sliderStyle} onMouseEnter={() => setMTHover(true)} onMouseLeave={() => setMTHover(false)}>
                    {mtHover ? mtDef : <Typography variant='subtitle1'> Max Tokens </Typography>}
                </div>
                <Slider
                    aria-label="Max Tokens To Sample"
                    defaultValue={300}
                    valueLabelDisplay="auto"
                    step={100}
                    marks
                    min={0}
                    max={4000}
                    onChange={(e) => handleMT(e)}
                />
            </div>
        </div>
    )
}

export function DetailPanelContent({row: details}){
    return(
        <Grid container justifyContent="center" width={'auto'} spacing={5}>
            <Grid item>
                <Card>
                    <CardContent>
                        <Typography variant='h6'> Client Answer: </Typography>
                        {/* <Typography variant='body1'> {details.row.answer}</Typography> */}
                          <TextareaAutosize
                            maxRows={4}
                            onChange={(e) => {console.log(e)}} 
                            defaultValue={ details.row.answer } /> 
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card>
                    <CardContent>
                        <Typography variant='h6'> ARCH Insights: </Typography>
                        {/* <Typography variant='body1'> {details.row.insight}</Typography> */}
                        <TextareaAutosize
                            maxRows={4}
                            onChange={(e) => {console.log(e)}} 
                            defaultValue={ details.row.insight } /> 
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}