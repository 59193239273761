import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { AppBar, Box, Toolbar, Button, IconButton, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import ArchSecondaryLogo from '../assets/archcollective-logo-secondary-black.svg'

export function Header({signOut}){
    const route = useNavigate()
    const [ anchorEl, setAnchorEl ] = useState(null)

    const handleOpenMenu = e => {
        setAnchorEl(e.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    return(
        <div>
            <header>
                <AppBar color="inherit" position="static" elevation={1} align='left'>
                    <Box sx={{flexGrow: 1, padding:'10px', alignItems:'center', width:'150px', height: '75px'}}>
                        <Link to="/">
                            <img src={ ArchSecondaryLogo } alt="arch_secondary_logo"/>
                        </Link>
                    </Box>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                            onClick={handleOpenMenu}
                        >
                            <MenuIcon  />
                        </IconButton>
                        <Menu  id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem onClick={signOut} fontFamily='Glook'> Logout </MenuItem>
                        </Menu>
                            <Button variant="h6" component="div" sx={{ flexGrow: 1, fontFamily:'Glook'}} onClick={() => route('/')}> Home </Button>
                            <Button variant="h6" component="div" sx={{ flexGrow: 1, fontFamily:'Glook'}} onClick={() => route('/chat_history')}> Chat History </Button>
                    </Toolbar>
                </AppBar> 
            </header>
        </div>
    )
}