import React from 'react';
import {StorageManager} from '@aws-amplify/ui-react-storage'

const S3BucketSubmitButton = () => {
	return (
		<div>
			<StorageManager
				acceptedFileTypes={['*']}
				accessLevel="guest"
				autoUpload={true}
				isResumable={true}
				maxFileCount={1}
				onUploadStart={() => {
					console.log('Upload started')
				}}
				onUploadError={() => {
					console.log('Upload error')
				}}
				onUploadSuccess={() => {
					console.log('Upload success')
				}}
			/>
		</div>
	)
}

export default S3BucketSubmitButton;